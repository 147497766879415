import { Fragment, useEffect, useState } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import UiDialog from '../common/ui/UiDialog';
import UiFormField from '../common/ui/UiFormField';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import {
    addAppointment,
    getAppoinmentTopics,
    getSlotsByTopic,
} from '../../services/apiService';
import Loader from '../common/Loader';
import { dayStartTimestamp, _timestamp } from '../../utils/dateUtil';
import moment from 'moment';
import { ApplicationStore } from '../../models';
import { AlertData, showAlert } from '../../store/actions/feedback';
import { loadAppointments } from '../../store/actions/appointmentEvents';
import { loadNotificationCount } from '../../store/actions/count';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import DeflectionModal from './DeflectionModal';
import UiText from '../common/ui/UiText';

interface AppoinmentModalProps {
    open: boolean;
    handleClose: (result?: any) => void;
    topics?: string[] | null;
    accountantId?: string;
    showAlert: (data: AlertData) => void;
    loadNotificationCount: () => void;
    subTopics?: string[]|null;
    user: any;
    deflectionFilter?: boolean | null;
}

const TAX_PREP_TOPICS = [
    'Quarterly Business Review',
    'Tax Planning',
    'Year End Tax Planning',
    'Business Tax Preparation',
    'Personal Tax Preparation'
]

const SHOW_WIDGET_SUB_TOPIC = 'Review completed return with Advisor';

function AppoinmentModal({
    open,
    handleClose,
    topics = null,
    accountantId,
    showAlert,
    loadNotificationCount,
    subTopics,
    user,
    deflectionFilter,
    ...props
}: AppoinmentModalProps) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        marginVertical: {
            marginTop: 20,
            marginBottom: 20,
        },
        marginBottom: {
            marginBottom: 20,
        },
        reviewOrderButton: {
            '&:not(:disabled)': {
                backgroundColor: colorTheme.orange300,
                color: colorTheme.primaryWhite,
            },
        }
    })();

    const dispatch = useDispatch();
    const [topicsFromApi, setTopicsFromApi] = useState<any[]>([]);
    const [topicsForSelect, setTopicsForSelect] = useState<string[]>([]);
    const [showError, setShowError] = useState('');
    const [allMappedTimeSlots, setAllMappedTimeSlots] = useState<
        { date: number; timeSlots: { time: string; timestamp: number }[] }[]
    >([]);
    const [allEnabledSlots, setAllEnabledSlots] = useState<number[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const [nextStepEnabled, setNextStepEnabled] = useState(false);
    const [openDeflectionModal, setOpenDeflectionModal] = useState(false);
    const [taxSubTopics, setTaxSubTopics] = useState<string[]>([]);
    const [showSubTopic, setShowSubTopic] = useState(false);
    const [taxTopic, setTaxTopic] = useState<string>();
    const [showCloseModalText, setShowCloseModalText] = useState(false);
    const [taxSubTopic, setTaxSubTopic] = useState<string>();

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({
        topic: '',
        date: null,
        slot_override: '30',
        start_time: null,
        description: '',
    });
    const [onboardingAccountant, setOnboardingAccountant] = useState('');
    const [onboardingTopic, setOnboardingTopic] = useState('');
    const [validationSchema, setValidationSchema] = useState(
        Yup.object({
            topic: Yup.string().required('Topic is required'),
        })
    );
    const [assignedAccountantID, setAssignedAccountantID] = useState('')

    //@ts-ignore    
    const {config} = props;
    const restrictAccountantOverride = config?.apiConfig?.topics?.restrict_accountant_override || [];

    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    useEffect(() => {
        if (topics) {
            setTopicsForSelect(topics);
            setLoadingData(false);
        } else {
            if (open) {
                getAppoinmentTopics().then((res: any) => {
                    if (res && res[0] && typeof res[0] === 'object') {
                        setTopicsFromApi(res);
                        setTopicsForSelect(
                            res.map((topic: any) => topic.topic)
                        );
                    } else {
                        setTopicsForSelect(res);
                    }
                });
                setLoadingData(false);
            }
        }
    }, [open, topics]);

    const getAccountant = (topic: string) => {
        if(assignedAccountantID && restrictAccountantOverride.includes(topic)){
            return assignedAccountantID;
        }

        if(onboardingAccountant){
            return onboardingAccountant;
        }
        if (accountantId) {
            return accountantId;
        } else if (topicsFromApi && topicsFromApi.length > 0) {
            return (
                topicsFromApi.find((t: any) => t.topic === topic)?.accountant ||
                null
            );
        }
        return null;
    };

    const nextStep = (data: any) => {
        setLoadingData(true);
        getSlotsByTopic<{
            scheduling_warning: any;
            slots: any;
        }>(data.topic, getAccountant(data.topic))
            .then((res : any) => {
                const topic = res.onboardingTopic || data.topic;
                setOnboardingAccountant(res.onboardingAccountant);
                setOnboardingTopic(topic);
                setAssignedAccountantID(res.assignedAccountantID);
                const slots = res.slots[topic];
                if (!slots.length) {
                    setInitialValues({
                        ...initialValues,
                        topic: topic,
                    });
                    setShowError(
                        'We were unable to find an available appointment time. Please call 1-800-222-6868 for assistance.'
                    );
                    setLoadingData(false);
                } else {
                    if (res.scheduling_warning) {
                        setShowError(
                            res.scheduling_warning.replace(
                                /<date>(\d+)<\/date>/,
                                function (match: any, date: any) {
                                    return moment(date * 1000).format(
                                        'MMM D yyyy [at] h:mm A'
                                    );
                                }
                            )
                        );
                    }

                    const mappedTimeSlots: {
                        date: number;
                        timeSlots: {
                            time: string;
                            timestamp: number;
                        }[];
                    }[] = [];
                    const allEnabledSlotsTmp = slots.reduce(
                        (result: number[], current: any) => {
                            const dayStart: any = dayStartTimestamp(
                                current * 1000
                            );
                            const foundIndex = result.indexOf(dayStart);
                            if (foundIndex === -1) {
                                result.push(dayStart);
                                mappedTimeSlots.push({
                                    date: dayStart,
                                    timeSlots: [
                                        {
                                            time: moment(current * 1000).format(
                                                'hh:mm A'
                                            ),
                                            timestamp: current,
                                        },
                                    ],
                                });
                            } else {
                                mappedTimeSlots[foundIndex].timeSlots.push({
                                    time: moment(current * 1000).format(
                                        'hh:mm A'
                                    ),
                                    timestamp: current,
                                });
                            }
                            return result;
                        },
                        []
                    );

                    setAllEnabledSlots(allEnabledSlotsTmp);
                    setAllMappedTimeSlots(mappedTimeSlots);
                    setInitialValues({
                        ...initialValues,
                        topic: data.topic,
                        date: allEnabledSlotsTmp[0],
                        start_time: mappedTimeSlots[0].timeSlots[0].timestamp,
                    });
                    setNextStepEnabled(true);

                    setValidationSchema(
                        Yup.object({
                            topic: Yup.string().required('Topic is required'),
                            date: Yup.number().required(
                                'Date and time is required'
                            ),
                            start_time: Yup.number().required(
                                'Date and time is requird'
                            ),
                            description: Yup.string(),
                        })
                    );
                    setLoadingData(false)
                    if(data.subTopic && data.subTopic !== SHOW_WIDGET_SUB_TOPIC){
                        setOpenDeflectionModal(true);
                    }
                    setShowSubTopic(false);
                }
            })
            .catch((err) => {
                showAlert({
                    alertType: 'error',
                    alertText: err?.statusText || 'Something went wrong',
                })
                setLoadingData(false)
            });
    };

    const showSubTopics = (topic: string) => {
        setTaxTopic(topic);
        if(
            (TAX_PREP_TOPICS.includes(topic)) 
            && subTopics && subTopics.length > 0
        ){
            setValidationSchema(
                Yup.object({
                    topic: Yup.string().required('Topic is required'),
                    subTopic: Yup.string().required('Subtopic is required'),
                })
            );
            setTaxSubTopics(subTopics ?? []);
            setShowSubTopic(true);
        }else{
            setValidationSchema(
                Yup.object({
                    topic: Yup.string().required('Topic is required'),
                })
            );
            setShowSubTopic(false);
        }
    };

    const shouldDisableDateFunction = (datePickerDate: any) => {
        let date = new Date(datePickerDate);
        return !allEnabledSlots.includes(_timestamp(date));
    };

    const onSubmit = (data: any) => {
        setFormSubmitted(true);
        if(onboardingTopic){
            data.topic = onboardingTopic;
        }
        addAppointment({
            ...data,
            accountant_id: getAccountant(data.topic)
                ? getAccountant(data.topic)
                : undefined,
        })
            .then((res: any) => {
                dispatch(loadAppointments());
                loadNotificationCount();
                showAlert({
                    alertText: `Your appointment has been successfully scheduled at ${moment(
                        res.start_time * 1000
                    ).format('hh:mm A')} on ${moment(
                        res.start_time * 1000
                    ).format('DD MMM yyyy')}.`,
                    alertType: 'success',
                });
                handleClose('success');
            })
            .catch(({ status, statusText }) => {
                showAlert({
                    alertText:
                        status === 418 || (statusText && statusText !== '')
                            ? statusText
                            : 'We were not able to schedule your appointment. Please try again.',
                    alertType: 'error',
                });
                handleClose('error');
            });
    };

    return (
        <>
        <UiDialog
            open={open}
            handleClose={handleClose}
            title={deflectionFilter ? 'Need Information?' : 'New Appointment'}
            size='sm'
        >
            {loadingData ? (
                <div className={styles.marginVertical}>
                    <Loader />
                </div>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={nextStepEnabled ? onSubmit : nextStep}
                    enableReinitialize
                    validateOnMount={true}
                >
                    {(formik) => {
                        const handleTopicChange = (e: any) => {
                            formik.setFieldValue('topic', e.target.value);
                            setNextStepEnabled(false)
                            setShowError('')
                        }
                        return (
                            <Form>
                                {
                                    showCloseModalText &&
                                    <>
                                        <UiText 
                                            variant={'motorcycle_90'} 
                                            textAlign={'center'} 
                                            textColor={'error'} 
                                            weight={'semi_bold_600'}
                                            className={styles.marginBottom}
                                        >
                                            Found the answers you were looking for? Go ahead and close this window.<br />
                                            If you still need further assistance, schedule an appointment now.
                                        </UiText>
                                    </>
                                }
                                {showError ? (
                                    <Typography
                                        color='error'
                                        variant='body1'
                                        gutterBottom
                                    >
                                        {showError}
                                    </Typography>
                                ) : (
                                    ''
                                )}

                                <UiFormField
                                    type='select'
                                    placeholder='Select a Topic'
                                    label='Select a Topic'
                                    fieldName='topic'
                                    optionsData={topicsForSelect}
                                    optionKey=''
                                    optionValue=''
                                    disabled={nextStepEnabled}
                                    onChange={(event) => {
                                        showSubTopics(event.target.value);
                                        handleTopicChange(event);
                                    }}
                                />
                                {showSubTopic &&
                                    <UiFormField
                                        type='select'
                                        placeholder='Select a Subtopic'
                                        label='Select a Subtopic'
                                        fieldName='subTopic'
                                        optionsData={taxSubTopics}
                                        optionKey=''
                                        optionValue=''
                                        onChange={(event) => {
                                            setTaxSubTopic(event.target.value);
                                        }}
                                    />
                                }
                                {nextStepEnabled ? (
                                    <Fragment>
                                        <UiFormField
                                            type='date'
                                            datePickerVariant={
                                                smDevice ? 'dialog' : 'static'
                                            }
                                            label='Date & Time'
                                            disableDatePickerToolbar={true}
                                            fieldName='date'
                                            provideDayStartDate={true}
                                            shouldDisableDateFunction={
                                                shouldDisableDateFunction
                                            }
                                        />
                                        <UiFormField
                                            type='select'
                                            placeholder='Select Time'
                                            label='Select Time'
                                            fieldName='start_time'
                                            optionsData={
                                                allMappedTimeSlots.find(
                                                    (timeSlot) =>
                                                        timeSlot.date ===
                                                        formik.values.date
                                                )?.timeSlots || []
                                            }
                                            optionKey='time'
                                            optionValue='timestamp'
                                            fastField={false}
                                        />
                                        <UiFormField
                                            type='textarea'
                                            placeholder='Write a description'
                                            label=''
                                            labelSize={false}
                                            fieldName='description'
                                        />
                                    </Fragment>
                                ) : (
                                    ''
                                )}

                                {formSubmitted ? (
                                    <div className={styles.marginVertical}>
                                        <Loader />
                                    </div>
                                ) : (
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        color='primary'
                                        className={styles.marginVertical}
                                        type='submit'
                                        disabled={!formik.isValid}
                                        onClick={() => {
                                            formik.validateForm();
                                        }}
                                    >
                                        { nextStepEnabled ? 'Schedule Appointment' : 'Continue' }
                                    </Button>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </UiDialog>
        {
            (nextStepEnabled && taxTopic && taxSubTopic) && 
                <DeflectionModal 
                    open={openDeflectionModal}
                    handleClose={() => {
                        setShowCloseModalText(true)
                        setOpenDeflectionModal(false)
                    }}
                    firstName={user.first_name}
                    lastName={user.last_name}
                    email={user.username}
                    schedulerTopic={taxTopic}
                    schedulerSubTopic={taxSubTopic}
                />
        }
        </>
    );
}

const mapStateToProps = ({ config, appData }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
    user: appData.user
});
export default connect(mapStateToProps, { showAlert, loadNotificationCount })(
    AppoinmentModal
);
