import { RefObject, useRef, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { showError } from '../../../../../services/formService'
import { editVehicle, postVehicle } from '../../../../../services/mileage-log/vehicleService'
import store from '../../../../../store'
import { showAlert } from '../../../../../store/actions/feedback'
import { useCurrentStore } from '../../../../common/hooks/useCurrentStore'
import { AddEditVehicleModal } from './AddEditVehicleModal'
import {
    ADD_EDIT_DELETE_SUCCESS_MSGS,
    FormData,
    FORM_ERRORS,
    MILEAGE_YEAR_KEY,
    OdometerReading,
    UNSAVED_MODAL_ACTIONS,
    UNSAVED_MODAL_ACTIONS_TYPES,
    VehicleDetailsAPI,
    VEHICLE_STATUSES,
    VIN_REGEX,
    Item,
} from '../../models/vehicle.model'
import { FormikProps } from 'formik'
import { UnsavedChangesDialogue } from '../UnsavedChangesDialogue'

interface Props {
    initialFormValues: FormData
    mileageYears: number[] | undefined
    showModal: boolean
    onModalClose: (isOpen: boolean) => void
    refreshVehicles: () => void
    isEditMode: boolean,
    selectedVehicleId? : string,
    callbackVehicle?: any
}
const validationSchema = {
    activeVehicle: Yup.boolean(),
    defaultVehicle: Yup.boolean(),
    make: Yup.string().required(FORM_ERRORS.MAKE.REQUIRED),
    model: Yup.string().required(FORM_ERRORS.MODEL.REQUIRED),
    year: Yup.number()
    .integer(FORM_ERRORS.YEAR.VALID)
    .required(FORM_ERRORS.YEAR.REQUIRED)
    .min(1930, FORM_ERRORS.YEAR.MIN)
    .max(new Date().getFullYear() + 1, FORM_ERRORS.YEAR.MAX),
    licensePlate: Yup.string(),
    vin: Yup.string()
    .matches(VIN_REGEX, FORM_ERRORS.VIN.VALID),
    description: Yup.string()
}

export function AddEditVehicle(props: Props) {
    const {
        initialFormValues,
        mileageYears,
        showModal,
        onModalClose,
        refreshVehicles,
        isEditMode,
        selectedVehicleId,
        callbackVehicle
    } = props
    const [isSubmittingInfo, setIsSubmittingInfo] = useState<boolean>(false)
    const [isOpenUnsavedModal, setIsOpenUnsavedModal] = useState<boolean>(false)
    const [mileageYearsInDesc, setMileageYearsInDesc] =
    useState<Array<number>>()
    const { currentAccountId } = useCurrentStore()
    const formRef: RefObject<FormikProps<FormData>> =
        useRef<FormikProps<FormData>>(null)
    const [validationSchemaDynamic, setValidationSchemaDynamic] = useState<any>();

    useEffect(() =>{
        if (!mileageYears) return
        const yearsInDesc = mileageYears.sort((a: number, b: number) => {
            return b - a
        })
        setMileageYearsInDesc(yearsInDesc)
    },[mileageYears])

    useEffect(() =>{
        if(!mileageYears) return;
        const validationSchemaNew = Yup.object({
            ...validationSchema,
            ...mileageYears.reduce((schema, year) => {
                return {
                    ...schema,
                    [`mileageYear${year}`]: Yup.number()
                        .integer(FORM_ERRORS.ODOMETER_READING.WHOLE_NUMBER)
                        .min(0, FORM_ERRORS.ODOMETER_READING.POSITIVE)
                };
            }, {})
        });
        setValidationSchemaDynamic(validationSchemaNew)
    },[mileageYears])

    const getFormattedOdometerReadings = (
        form: FormData
    ): OdometerReading | {} => {
        const odometerReadings: OdometerReading = {}
        Object.keys(form).forEach((key) => {
            if (key.startsWith(MILEAGE_YEAR_KEY) && form[key]) {
                const year = key.replace(MILEAGE_YEAR_KEY, '')
                odometerReadings[year] = form[key]
            }
        })
        return odometerReadings
    }
    const getFormattedDataForPost = (form: FormData): VehicleDetailsAPI => {
        return {
            make: form.make,
            model: form.model,
            year: form.year,
            license_plate_number: form.licensePlate,
            vin: form.vin,
            status: form.activeVehicle
                ? VEHICLE_STATUSES.ACTIVE
                : VEHICLE_STATUSES.INACTIVE,
            is_default: form.defaultVehicle,
            description: form.description,
            odometer_readings: getFormattedOdometerReadings(form),
        }
    }
    const vehicleAddSuccess = (message : string, vehicle?: Item) : void =>{
        setIsSubmittingInfo(false)
        store.dispatch(
            showAlert({
                alertType: 'success', 
                alertText: message,
            })
        )
        onModalClose(false)
        refreshVehicles()
        if(vehicle){
            callbackVehicle?.(vehicle)
        }
    }
    const handleAddNewVehicle = (data: VehicleDetailsAPI) =>{
        setIsSubmittingInfo(true)
        postVehicle(currentAccountId, data)
            .then((res: Item) => {
                vehicleAddSuccess(ADD_EDIT_DELETE_SUCCESS_MSGS.ADD, res)
            })
            .catch((error) => {
                setIsSubmittingInfo(false)
                showError(error?.statusText)
            })
    }
    const handleEditVehicle = (data: VehicleDetailsAPI) =>{
        setIsSubmittingInfo(true)
        editVehicle(currentAccountId, selectedVehicleId as string, data)
            .then((res: unknown) => {
                vehicleAddSuccess(ADD_EDIT_DELETE_SUCCESS_MSGS.EDIT, res as Item)
            })
            .catch((error) => {
                setIsSubmittingInfo(false)
                showError(error?.statusText)
            })
    }
    const handleFormSubmit = (form: FormData) => {
        const data = getFormattedDataForPost(form)
        if(!isEditMode){
            handleAddNewVehicle(data)
            return;
        }
        handleEditVehicle(data)
    }
    const closeEditModal = (isOpen : boolean) =>{
        onModalClose(isOpen)
    }
    const handleUsavedChangesModalAction = (action: UNSAVED_MODAL_ACTIONS) => {
        if(action === UNSAVED_MODAL_ACTIONS_TYPES.DISCARD){
            closeEditModal(false)
        }
        setIsOpenUnsavedModal(false)
    }
    const onModalCloseAction = (isOpen: boolean) =>{
        formRef.current?.dirty ? setIsOpenUnsavedModal(true) : closeEditModal(isOpen)
    }
    return (
        <>
            <AddEditVehicleModal
                initialFormValues={initialFormValues}
                handleFormSubmit={handleFormSubmit}
                validationSchema={validationSchemaDynamic}
                mileageYears={mileageYearsInDesc}
                showModal={showModal}
                onModalClose={onModalCloseAction}
                isSubmittingInfo={isSubmittingInfo}
                formRef={formRef}
                isEditMode={isEditMode}
            />
            <UnsavedChangesDialogue
                open={isOpenUnsavedModal}
                handleModalAction={handleUsavedChangesModalAction}
            />
        </>
    )
}
